import crm from "./crm";
import guService from "./GuService";
import { trackPromise } from "react-promise-tracker";

const oportunidadeService = {
  buscarOportunidades: () => {
    return crm.get("/oportunidade");
  },
  buscarOportunidadesPaginado: (limit, page, filtros) => {
    return crm.get(`/oportunidade?limit=${limit}&page=${page}`, {
      params: filtros,
    });
  },
  buscarOportunidadesPorStatus: (id) => {
    return crm.get(`/oportunidade/oportunidadesPorStatus/${id}`);
  },
  buscarOportunidadesPorStatusPaginado: (id, limit, page, filtros) => {
    return crm.get(
      `/oportunidade/oportunidadesPorStatus/${id}?limit=${limit}&page=${page}`,
      { params: filtros }
    );
  },
  buscarOportunidade: (id, filtros=null) => {
    return crm.get(`/oportunidade/${id}`, { params: filtros });
  },

  buscarEtapas: () => {
    return crm.get('/oportunidade/buscarEtapas');
  },

  buscarTotais: (filtros) => {
    return crm.get(`/oportunidade/getTotalCount`, { params: filtros });
  },
  buscarCountStatus: (id, filtros = null) => {
    return crm.get(`/oportunidade/getTotalCountStatus/` + id, {
      params: filtros,
    });
  },

  buscarPorStatus: (filtros = null) => {
    guService.setLocalState('filtros', JSON.stringify(filtros));
    return crm.get("/oportunidade/listByStatus", { params: filtros });
  },
  buscarListaStatus: () => {
    return crm.get("/oportunidade/listStatus");
  },
  buscarMidias: () => {
    return crm.get("/midia");
  },
  buscarComoConheceu: () => {
    return crm.get("/comoconheceu");
  },
  buscarNivelInteresse: () => {
    return crm.get("/nivelinteresse");
  },
  editNivelInteresse: (dados, id) => {
    let uri = '/nivelinteresse/' + id;
    return crm['put'].call(crm, uri, dados);
  },
  storeNivelInteresse: (dados) => {
    let uri = '/nivelinteresse';
    return crm['post'].call(crm, uri, dados);
  },
  buscarOrigens: () => {
    return crm.get("/origem");
  },
  buscarTodasOrigens: () => {
    return crm.get("/origem/todos");
  },
  buscaExpiracao: (id) => {
    return crm.get("/oportunidade/getExpiracao/" + id);
  },
  buscarListaEnvolvidos: (id) => {
    return crm.get("/oportunidade/getEnvolvidos/" + id);
  },
  buscarListaTelefonesEnvolvidos: (id) => {
    return crm.get("/oportunidade/getTelefonesEnvolvidos/" + id);
  },
  buscarListaEmailsEnvolvidos: (id) => {
    return crm.get("/oportunidade/getEmailsEnvolvidos/" + id);
  },
  buscarListaAtendimentos: (id) => {
    return crm.get("/oportunidade/atendimento/getByOportunidade/" + id);
  },
  buscarTiposAtendimentos: () => {
    return crm.get("/oportunidade/atendimento/getTiposAtendimentos");
  },
  buscarListaAnexos: (id) => {
    return crm.get("/oportunidade/anexo/getByOportunidade/" + id);
  },
  buscarListaPendencias: (id) => {
    return crm.get("/oportunidade/pendencia/getByOportunidade/" + id);
  },
  buscarListaInteresses: (id) => {
    return crm.get("/oportunidade/interesse/getByOportunidade/" + id);
  },
  buscarTiposTarefa: () => {
    return crm.get("/tipo-tarefa");
  },
  buscarListaOportunidadePorNome: (dados) => {
    return crm.get("/oportunidade/listar-por-nome", { params: dados });
  },
  buscarListaVisitasPorUsuario: (filtros = null, isVisitsChecked, isTasksChecked) => {
    return crm.get(`/oportunidade/atendimento/eventos-usuario/${isVisitsChecked}/${isTasksChecked}`, {
      params: filtros,
    });
  },
  getEventsList: (filtros = null, isVisitsChecked, isTasksChecked, interval, page=0, limit=0) => {
    return crm.get(`/oportunidade/atendimento/eventos/${isVisitsChecked}/${isTasksChecked}/${interval}?limit=${limit}&page=${page}`, {
      params: filtros,
    });
  },
  getEventsCount: (filtros = null, isVisitsChecked, isTasksChecked, interval) => {
    return crm.get(`/oportunidade/atendimento/events-interval/${isVisitsChecked}/${isTasksChecked}/${interval}`, {
      params: filtros,
    });
  },
  buscarListaTarefasPorUsuario: () => {
    return crm.get("/oportunidade/atendimento/tarefas-agendadas-usuario");
  },
  buscarTiposPendencia: () => {
    return crm.get("/oportunidade/pendencia/getTiposPendencia");
  },
  buscarTiposDesistencia: (filtros = null) => {
    return crm.get("/oportunidade/desistencia/getTiposDesistencia", { params: filtros });
  },
  buscarTiposAnexo: () => {
    return crm.get("/oportunidade/anexo/getTiposList");
  },
  buscarVisitasAgendadas: () => {
    return crm.get("/oportunidade/atendimento/visitasAgendadas");
  },
  calcularTotalVisualizacoesListaPorStatus: (lista = []) => {
    let somatorioLista = 0;
    lista.forEach((oportunidade) => {
      somatorioLista += oportunidade.lista.length;
    });
    return somatorioLista;
  },
  enviarParaAtendimentoPdv: (oportunidade) => {
    return crm.put(
      "/oportunidade/" + oportunidade.id_oportunidade + "/enviar-para-pdv"
    );
  },
  filtrarAtendimentos: (filtros, exportar, limite, pagina) => {
    return crm.get(`/oportunidade/atendimento/filtrar-atendimentos`, {params: {filtros, exportar, limite, pagina}})
  },
  filtrarListaStatusPorOportunidadeLeads: (filtros = {}) => {
    return new Promise((resolve) => {
      crm.get("oportunidade/getAll").then(
        (res) => {
          const novoStatus = [];
          Object.values(res.data).map((status) => {
            status.lista = status.lista.filter((oportunidade) => {
              if (filtros.oportunidade) {
                for (let filtroOportunidade in filtros.oportunidade) {
                  if (filtros.oportunidade[filtroOportunidade] || false) {
                    if (
                      oportunidade[filtroOportunidade] !==
                      Number(filtros.oportunidade[filtroOportunidade])
                    ) {
                      return false;
                    }
                  }
                }
              }
              if (filtros.lead) {
                for (let filtroLead in filtros.lead) {
                  if (filtros.lead[filtroLead] || false) {
                    if (
                      filtroLead === "nome" ||
                      filtroLead === "email" ||
                      filtroLead === "telefone"
                    ) {
                      if (
                        oportunidade.leads_oportunidade.some(
                          (leadOportunidade) => {
                            return leadOportunidade.lead[filtroLead]
                              ?.toLowerCase()
                              .includes(filtros.lead[filtroLead].toLowerCase());
                          }
                        )
                      ) {
                        return true;
                      }
                    }

                    if (
                      !oportunidade.leads_oportunidade.some(
                        (leadOportunidade) =>
                          leadOportunidade.lead[filtroLead] ===
                          Number(filtros.lead[filtroLead])
                      )
                    ) {
                      return false;
                    }
                  }
                }
              }

              return true;
            });
            novoStatus.push(status);
          });
          resolve(novoStatus);
        },
        (error) => {
          console.log(error)
        }
      );
    });
  },
  salvarLead: (lead, oportunidade) => {
    let uri = "/lead";
    let method = "post";
    if (lead.id_lead || false) {
      uri = "/lead/" + lead.id_lead;
      method = "put";

      return crm.put(uri, {lead:lead, oportunidade: oportunidade});
    }

    return crm.post(uri, {lead:lead, oportunidade: oportunidade});
    // return crm[method].call(crm, uri, {
    //   lead: lead,
    //   oportunidade: oportunidade,
    // });
  },
  salvarAnexo: (anexo) => {
    let uri = "/oportunidade/anexo";
    let method = "post";
    if (anexo.id_oportunidade_anexo || false) {
      uri = "/oportunidade/anexo/" + anexo.id_oportunidade_anexo;
      method = "put";
    }
    return crm[method].call(crm, uri, anexo);
  },
  salvarInteresse: (interesse) => {
    let uri = "/oportunidade/interesse";
    let method = "post";
    if (interesse.id_oportunidade_interesse) {
      uri = "/oportunidade/interesse/" + interesse.id_oportunidade_interesse;
      method = "put";
    }
    return crm[method].call(crm, uri, { interesse: interesse });
  },
  salvarEnvolvido: (envolvido, id_oportunidade) => {
    let uri = "/lead/envolvido";
    let method = "post";
    if (envolvido.id_envolvido) {
      uri = "/lead/envolvido/" + envolvido.id_envolvido;
      method = "put";
      return crm[method].call(crm, uri, { envolvido: envolvido });
    }
    return crm[method].call(crm, uri, {
      envolvido: envolvido,
      id_oportunidade: id_oportunidade,
    });
  },
  salvarOportunidade: (data, enviarFunildoCorretor = { enviar:false }) => {
    let uri = "/oportunidade";
    let method = "post";
    if (data.oportunidade.id_oportunidade) {
      uri = "/oportunidade/" + data.oportunidade.id_oportunidade;
      method = "put";
    }
    return crm[method].call(crm, uri, { data, enviarFunildoCorretor });
  },
  salvarAtendimento: (atendimento) => {
    let uri = "/oportunidade/atendimento";
    let method = "post";
    if (atendimento.id_oportunidade_atendimento || false) {
      uri =
        "/oportunidade/atendimento/" + atendimento.id_oportunidade_atendimento;
      method = "put";
    }
    return crm[method].call(crm, uri, { atendimento: atendimento });
  },
  alterarStatusAtendimento: (id_oportunidade_atendimento, status) => {
    return crm.put(
      `/oportunidade/atendimento/alterarStatusTarefa/${id_oportunidade_atendimento}`,
      { status }
    );
  },
  alterarStatusListaVisitas: () => {
    return crm.put(`/perfil-usuario/alterar-visualizacao-visita`);
  },
  alterarStatusPendentes: () => {
    return crm.put(`/perfil-usuario/visualiza-eventos-pendentes`);
  },
  salvarPendencia: (pendencia) => {
    let uri = "/oportunidade/pendencia";
    let method = "post";
    if (pendencia.id_oportunidade_pendencia || false) {
      uri = "/oportunidade/pendencia/" + pendencia.id_oportunidade_pendencia;
      method = "put";
    }
    return crm[method].call(crm, uri, { pendencia: pendencia });
  },
  solicitarClone: (solicitacao) => {
    return crm.post(`/solicitacao-clonagem`, { solicitacao });
  },
  aprovarClonagem: (id, solicitacao, oportunidade) => {
    return crm.put(`/solicitacao-clonagem/${id}`, {
      solicitacao: solicitacao.solicitacao,
      oportunidade: oportunidade.oportunidade,
      lead: solicitacao.lead
    });
  },
  buscarSolicitacoes: (limit, page) => {
    return crm.get(`/solicitacao-clonagem?limit=${limit}&page=${page}`);
  },
  excluirOportunidade: (oportunidade) => {
    let data = {};

    let dados = {
      ativo: "0",
    };
    data.oportunidade = dados;
    return crm["put"].call(crm, "/oportunidade/" + oportunidade, {
      data,
    });
  },
  excluirAnexo: (id) => {
    return crm["delete"].call(crm, "/oportunidade/anexo/" + id);
  },
  excluirAtendimento: (id) => {
    return crm["delete"].call(crm, "/oportunidade/atendimento/" + id);
  },
  excluirPendencia: (id) => {
    return crm["delete"].call(crm, "/oportunidade/pendencia/" + id);
  },
  excluirInteresse: (id) => {
    return crm["delete"].call(crm, "/oportunidade/interesse/" + id);
  },
  atualizarStatus: (dados) => {
    let uri = "/oportunidade";
    let method = "post";
    let data = {};
    data.oportunidade = dados;
    if (dados.id_oportunidade || false) {
      uri = "/oportunidade/" + dados.id_oportunidade;
      method = "put";
    }
    return crm[method].call(crm, uri, { data });
  },
  desistencia: (dados) => {
    let uri = "/oportunidade/desistencia";
    let method = "post";
    return crm[method].call(crm, uri, dados);
  },
  buscarCockpit: () => {
    return crm.get("/oportunidade/cockpit");
  },

  alteracaoEmMassa: (dados) => {
    let uri = "/oportunidade/em-massa";
    return crm["put"].call(crm, uri, dados);
  },

  alteracaoEmMassaBuscaTodos: (id, filtros) => {
    return crm.get(`oportunidade/oportunidadesPorEtapa/${id}`, { params: filtros });
  },

  salvarNovoLeadOPV: (dados) => {
      let uri = '/lead';
      return crm['post'].call(crm, uri, dados);
  },

  salvarNovoOPV: (lead, lead_dados, oportunidade, interesse) => {
    return crm.post(`/oportunidade/desvincular-lead`, { lead, oportunidade, interesse, lead_dados });
  },

  transferirLead: (lead, opv) => {
    return crm.post(`/oportunidade/desvincular-lead-opv-existente`, { lead, opv });
  },

  buscarCamposDinamicos: (limit=8) => {
    return crm.get(`campos-dinamicos?limit=${limit}`);
  },

  buscarCamposDinamicosTelaQualificacao: () => {
    return crm.get(`campos-dinamicos/tela-qualificacao`);
  },

   buscarAgenda: (dataInicio, dataFim, isVisitsChecked, isTasksChecked, filtros) => {
    return crm.get(`oportunidade/atendimento/eventosAgendaSemana/${dataInicio}/${dataFim}/${isVisitsChecked}/${isTasksChecked}`, { params: filtros });
  },

  searchOperatorsInfo: (id_oportunidade) => {
    return crm.get(`oportunidade/operators/${id_oportunidade}`)
  },

  unifyOpvs: (data) => {
    return trackPromise(crm.post('oportunidade/unify', data))
  },
  conteudosAtendimentoConecta: (id_atendimento_prospecta,page) => {
    return crm.get(`/busca-conteudo-atendimento?id_atendimento_prospecta=${id_atendimento_prospecta}&page=${page}`)
  },
  buscaGeralOportunidades: (pageParam, options = {}, pesquisa) => {
    let uri = `/oportunidade/buscaGeralOportunidades?limit=5&page=${pageParam}`;
    return crm.post(uri, pesquisa , options);
  },
  criarAtendimentoConecta: (tipo, id_oportunidade) => {
    return trackPromise(crm.post('criar-atendimento-conecta', { tipo, id_oportunidade }))
  },
  atualizarAtendenteOportunidade(idOportunidade, idAtendente) {
    return trackPromise(crm.patch(`/oportunidade/${idOportunidade}/atendimentos`, { idAtendente }))
  },
  checkCondicoesEtapaAtual: (idOportunidade) => {
    return crm.get(`/oportunidade/${idOportunidade}/condicoes-etapa-atual`);
  },
  criarBaseDisparo: (dados) => {
    return crm.post(`/oportunidade/base-disparo`, {dados});
  },

  redistributeOpvs:(opportunities) => {
    return crm.post('/oportunidade/redistribuir-fila',{opportunities})
  },
  idsTiposAtendimento: {
    FECHAMENTO_DE_PARCERIA: 10,
    VENDA_PARCEIRO: 11
  }
};

export default oportunidadeService;
