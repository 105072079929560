import crm from './crm';

const notificacaoService = {
	buscarNotificacoes:()=>{
		return crm.get('/notificacao/ultimas');
	},
	buscarNotificacao:(id)=>{
		return crm.get(`/notificacao/${id}`);
	},
	atualizar: (id) => {
        return crm['put'].call(crm, '/notificacao/atualizar/' + id);
    },
	atualizarNotificacoes: (id) => {
        return crm['put'].call(crm, '/notificacao/atualizar');
    },
	marcarComoNaoLida: (id) => {
        return crm['put'].call(crm, '/notificacao/atualizar/nao-lida/'  + id );
    },
    notificado: (id) => {
        return crm['put'].call(crm, '/notificacao/atualizar/notificado/'  + id );
    },
};

export default notificacaoService;
