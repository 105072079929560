import axios from 'axios';
import guService from "./GuService";



function getCookie(name) {
    const cookies = document.cookie;
    const parts = cookies.split(`${name}=`);

    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}


const crm = axios.create({
    baseURL: process.env.REACT_APP_CRM_API,
    headers: {
        'Authorization': guService.getLocalState('token'),
        'Content-Type': 'application/json',
        'X-Gu-Access-Token' : getCookie('gu_access_token')
    }
});

export default crm;
